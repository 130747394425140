import isValidDomain from 'is-valid-domain';
import { isIPv4, isIPv6 } from 'is-ip';

const extractHostnameFromUrl = (url: string): string => {
  try {
    // if url parse and return hostname
    const parsedUrl = new URL(url);
    return parsedUrl.hostname;
  } catch (err) {
    return url;
  }
};

const isValidHostname = (hostname: string): Object => {
  if (!hostname || hostname.length === 0) {
    const response = {
      valid: false,
      type: ''
    };
    return response;
  }

  const checkdomain = isValidDomain(hostname);
  if (checkdomain) {
    const response = {
      valid: true,
      type: 'domain'
    };
    return response;
  }

  const checkIPv4 = isIPv4(hostname);
  if (checkIPv4) {
    const response = {
      valid: true,
      type: 'ipv4'
    };
    return response;
  }

  const checkIPv6 = isIPv6(hostname);
  if (checkIPv6) {
    const response = {
      valid: true,
      type: 'ipv6'
    };
    return response;
  }

  return { valid: false, type: '' };
};

export { extractHostnameFromUrl, isValidHostname };
