export const urlBuilder = (
  continent,
  country,
  state,
  city,
  provider,
  asn,
  ipv6
) => {
  let data = {};
  if (state && country.includes('United States') && ipv6) {
    data = {
      continent: continent,
      country: country,
      state: state,
      city: city,
      provider: provider,
      asn: asn,
      ipv6: ipv6
    };
  } else if (state && country.includes('United States')) {
    data = {
      continent: continent,
      country: country,
      state: state,
      city: city,
      provider: provider,
      asn: asn
    };
  } else if (ipv6) {
    data = {
      continent: continent,
      country: country,
      state: [],
      city: city,
      provider: provider,
      asn: asn,
      ipv6: ipv6
    };
  } else {
    data = {
      continent: continent,
      country: country,
      state: [],
      city: city,
      provider: provider,
      asn: asn
    };
  }

  return data;
};
