import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Typography,
  Box,
  useTheme,
  Slide,
  Link,
  alpha,
  styled,
  lighten,
  Grid,
  Avatar
} from '@mui/material';
import dynamic from 'next/dynamic';
import { useTranslation } from 'react-i18next';
import { useWorker } from '@/ping.hook';
import ContinentContent from '@/content/Ping/Components/ContinentContent';
import Block3 from '@/content/Ping/Components/Block3';
import PingForm from '@/content/Ping/Components/PingForm';
import { isValidHostname } from '@/utils/domain';
import { useAuth } from '@/hooks/useAuth';
import { useSnackbar } from 'notistack';
import { Worker } from '@/models/worker';
import { closeSnackbarButton } from '@/components/CloseSnackbarButton';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import PingHistoryTable from '@/content/Ping/Components/PingHistoryTable';
import { format } from 'date-fns';
import NetworkWifiIcon from '@mui/icons-material/NetworkWifi';
import MTRDialog from './Components/MTRDialog';
import PingDialog from './Components/PingDialog';
import MapDialog from './Components/MapDialog';

const AvatarPageTitle = styled(Avatar)(
  ({ theme }) => `
      width: ${theme.spacing(8)};
      height: ${theme.spacing(8)};
      color: ${theme.colors.primary.main};
      margin-right: ${theme.spacing(2)};
      background: ${
        theme.palette.mode === 'dark'
          ? theme.colors.alpha.trueWhite[10]
          : theme.colors.alpha.white[50]
      };
      box-shadow: ${
        theme.palette.mode === 'dark'
          ? '0 1px 0 ' +
            alpha(lighten(theme.colors.primary.main, 0.8), 0.2) +
            ', 0px 2px 4px -3px rgba(0, 0, 0, 0.3), 0px 5px 16px -4px rgba(0, 0, 0, .5)'
          : '0px 2px 4px -3px ' +
            alpha(theme.colors.alpha.black[100], 0.4) +
            ', 0px 5px 16px -4px ' +
            alpha(theme.colors.alpha.black[100], 0.2)
      };
`
);

const WorkersMap = dynamic(
  () => import('@/content/Ping/Components/WorkersMap'),
  {
    ssr: false
  }
);

interface WorkersPerContinent {
  [key: string]: Array<any>;
}

function PingContent() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();

  const handleRunErrors = (errorType: string, data: any) => {
    if (errorType === 'runError') {
      console.error(data);
      enqueueSnackbar('An error has occured, please try again later!', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        autoHideDuration: 2000
        // TransitionComponent: Slide
      });
    }
    if (errorType === 'workerError') {
      console.error(data);
      enqueueSnackbar(
        'There are no workers for your filter selection. Please change filters or try again later!',
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          autoHideDuration: 2000
          // TransitionComponent: Slide
        }
      );
    }
    if (errorType === 'recaptchaError') {
      console.error(data);
      enqueueSnackbar(
        'We could not validate your reCAPTCHA. Please try again later!',
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          autoHideDuration: 2000
          // TransitionComponent: Slide
        }
      );
    }
    stop();
  };

  const {
    status: workerStatus,
    runStatus: runStatus,
    run: run,
    stop: stop,
    data: runData,
    reverseDns,
    resetState: resetRunState,
    reportId: pingReportId,
    reportDate: pingReportDate,
    reportDomain: pingReportDomain,

    workers
  } = useWorker(handleRunErrors);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const [hostname, setHostname] = useState('');
  const [isIPv6, setIsIPv6] = useState(false);
  const [openPingDialog, setOpenPingDialog] = useState(false);
  const [openMtrDialog, setOpenMtrDialog] = useState(false);
  const [openMapDialog, setOpenMapDialog] = useState(false);
  const [worker, setWorker] = useState({});
  const [inputType, setInputType] = useState('');

  // google recaptcha
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('run_ping');
    return token;
  }, [executeRecaptcha]);

  const workerIdWorkerMap = useMemo(() => {
    const newState: { [key: number]: Worker } = {};
    for (const worker of workers) {
      newState[worker.id] = worker;
    }
    return newState;
  }, [workers]);

  const workersPerContinent: WorkersPerContinent = useMemo(() => {
    const newState: WorkersPerContinent = {};
    for (const worker of workers) {
      const location = worker.location;
      if (!(location.continent in newState)) {
        newState[location.continent] = [];
      }
      newState[location.continent].push(worker);
    }

    return newState;
  }, [workers]);

  const workerIdContinentNameMap = useMemo(() => {
    const newState: { [key: number]: string } = {};
    for (const worker of workers) {
      newState[worker.id] = worker.location.continent;
    }
    return newState;
  }, [workers]);

  const dataPerContinent = useMemo(() => {
    const newState: { [key: string]: object } = {};
    for (const [workerId, data] of Object.entries(runData)) {
      const continent = workerIdContinentNameMap[workerId];

      if (!(continent in newState)) {
        newState[continent] = {};
      }
      newState[continent][workerId] = data;
    }
    return newState;
  }, [workerIdContinentNameMap, runData]);

  const handleSubmit = useCallback(
    async (formData: any) => {
      const recaptchaToken = await handleReCaptchaVerify();
      resetRunState();
      setInputType('');
      setHostname(formData.hostname);
      setIsIPv6(formData.isIPv6);

      const pingInputData = {
        location: {
          continent: formData.continent.map((i) => i.name),
          country: formData.country.map((i) => i.name),
          state: formData.state.map((i) => i.name),
          city: formData.city.map((i) => i.name),
          asn: formData.asn.map((i) => i.name),
          provider: formData.provider.map((p) => p.id)
        },
        count: 25,
        ipv6: formData.isIPv6,
        hostname: formData.hostname.trim(),
        recaptcha: recaptchaToken
      };
      run(pingInputData);
      const isValid = isValidHostname(formData.hostname);
      if (isValid.type === 'domain') {
        setInputType(isValid.type);
      }
    },
    [
      run,
      handleReCaptchaVerify,
      resetRunState,
      setInputType,
      setHostname,
      setIsIPv6
    ]
  );

  const handleClose = useCallback(() => {
    stop();
  }, [stop]);

  const handleLatencyClick = (data) => {
    setWorker(data);
    setOpenPingDialog(true);
  };

  const handleHopClick = (data) => {
    setWorker(data);
    setOpenMtrDialog(true);
  };

  const handleMapClick = (data: any) => {
    setWorker(data);
    setOpenMapDialog(true);
  };

  useEffect(() => {
    if (pingReportId) {
      enqueueSnackbar(
        // FIRST VARIANT
        <Typography sx={{ display: 'flex', flexDirection: 'column' }}>
          [{format(new Date(), 'dd.MM.yyyy HH:mm:ss')}] Ping finished!
          <Typography>
            Click
            <Link
              underline={'always'}
              target={'_blank'}
              href={`/reports/${pingReportId}/${pingReportDate}/${pingReportDomain}`}
              color={'#000000'}
            >
              {' '}
              here{' '}
            </Link>
            to view report
          </Typography>
        </Typography>,

        // SECOND VARIANT
        // <Typography sx={{display: 'flex', flexDirection: 'column' }}>
        //   [{format(new Date(),'dd.MM.yyyy HH:mm:ss')}]
        //   <Typography> Ping finished!</Typography>
        //   <Typography>Click
        //   <Link
        //     underline={'always'}
        //     target={'_blank'}
        //     href={`/reports/${pingReportId}`}
        //     color={'black'}
        //   >
        //     {' '}
        //     here{' '}
        //   </Link>
        //   to view report
        //   </Typography>
        // </Typography>

        // THIRD VARIANT
        // <Typography sx={{display: 'flex', flexDirection: 'column' }}>
        //     Ping finished! {' '}  [{format(new Date(),'dd.MM.yyyy HH:mm:ss')}]
        //     <Typography>Click
        //     <Link
        //       underline={'always'}
        //       target={'_blank'}
        //       href={`/reports/${pingReportId}`}
        //       color={'#000000'}
        //     >
        //       {' '}
        //       here{' '}
        //     </Link>
        //     to view report
        //     </Typography>
        //   </Typography>
        {
          variant: 'info',
          action: closeSnackbarButton,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          },
          autoHideDuration: 10000,
          // persist: true,
          TransitionComponent: Slide
        }
      );
    }
  }, [pingReportId]);

  const continentsList = [
    'Localhost',
    'North America',
    'Europe',
    'Asia',
    'Oceania',
    'South America',
    'Africa'
  ];

  return (
    <>
      <Box
        sx={{
          px: {
            xs: 1.5,
            sm: 3,
            lg: 4
          }
        }}
      >
        <Box
          display="flex"
          alignItems={{ xs: 'start', lg: 'center' }}
          paddingBottom={4}
          paddingTop={2}
        >
          <AvatarPageTitle variant="rounded">
            <NetworkWifiIcon fontSize="large" />
          </AvatarPageTitle>
          <Box>
            <Typography variant="h1" component="h1" gutterBottom>
              {t('Ping Latency Tool')}
            </Typography>
            <Typography variant="subtitle2">
              {t(
                'Test the latency of your server from all over the world with this awesome & free ping benchmark tool '
              )}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Block3 />
        </Box>
        <Box sx={{ mb: 4 }}>
          <PingForm
            isRunning={runStatus === 'running'}
            canStart={workerStatus === 'ready'}
            onSubmit={handleSubmit}
            handleClose={handleClose}
          />
        </Box>
        <Box sx={{ mb: 1 }}>
          <Typography variant="h4" component="p" gutterBottom>
            {t(
              'The Ping latency tool provided for free by Admin Tools is here to solve the ping problems we all know that exist in modern times. We provide ping from multiple available locations around the world. The high-quality Ping tool is based on high speed and it has been created with love and a lot of coffee. All the tests run in real-time and yes, from multiple worldwide locations. You can use this Ping tool to compare the CDN providers and their performance in different regions of the world. You can also use it as a debug tool for your own routing logic and also for geo load-balancing. '
            )}
          </Typography>
        </Box>

        <Box>
          <div id="submitScroll"></div>
          {continentsList.map(
            (continentName) =>
              (workersPerContinent[continentName] && (
                <ContinentContent
                  key={continentName}
                  continentName={continentName}
                  workers={workersPerContinent[continentName]}
                  hostname={hostname}
                  isIPv6={isIPv6}
                  onLatencyClick={handleLatencyClick}
                  onHopClick={handleHopClick}
                  onMapClick={handleMapClick}
                  data={dataPerContinent[continentName] || {}}
                  inputType={inputType}
                  user={user}
                  reverseDns={reverseDns}
                  isRunning={runStatus === 'running'}
                  date={null}
                />
              )) ||
              null
          )}
          {/* {Object.entries(workersPerContinent).map(
            ([continentName, workers]) => (
              <ContinentContent
                key={continentName}
                continentName={continentName}
                workers={workers}
                hostname={hostname}
                isIPv6={isIPv6}
                onLatencyClick={handleLatencyClick}
                onHopClick={handleHopClick}
                onMapClick={handleMapClick}
                data={dataPerContinent[continentName] || {}}
                inputType={inputType}
                user={user}
                reverseDns={reverseDns}
                isRunning={runStatus === 'running'}
                date={null}
              />
            )
          )} */}
        </Box>
        <Grid container display={'flex'} marginTop={5}>
          <Grid item xs={12} md={6} display={{ xs: 'none', lg: 'flex' }}>
            <WorkersMap />
          </Grid>
          <Grid item xs={12} md={6}>
            <PingHistoryTable pingReportId={pingReportId} />
          </Grid>
        </Grid>
        {/* Ping dialog */}
        <PingDialog
          open={openPingDialog}
          onClose={() => setOpenPingDialog(!openPingDialog)}
          worker={worker}
          hostname={hostname}
          isIPv6={isIPv6}
        />
        {/* MTR Dialog */}
        <MTRDialog
          open={openMtrDialog}
          onClose={() => setOpenMtrDialog(!openMtrDialog)}
          worker={worker}
          hostname={hostname}
          isIPv6={isIPv6}
        />
        {/* Map Dialog */}
        <MapDialog
          open={openMapDialog}
          onClose={() => setOpenMapDialog(!openMapDialog)}
          workers={[workerIdWorkerMap[worker.id]]}
          data={{ [worker.id]: runData[worker.id] }}
          zoom={4}
          header={worker}
          tooltipOpen={true}
          center={[
            workerIdWorkerMap[worker.id] &&
              workerIdWorkerMap[worker.id].location.coordinates?.latitude,
            workerIdWorkerMap[worker.id] &&
              workerIdWorkerMap[worker.id].location.coordinates?.longitude
          ]}
        />
      </Box>
    </>
  );
}

export default PingContent;
