import { Autocomplete, Button, Grid, TextField, Box } from '@mui/material';
import { useCallback, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useMemo } from 'react';
import { apiInstance } from '@/config/api';
import { useRefMounted } from '@/hooks/useRefMounted';
import { getFlagIcon } from '@/utils/flagIcon';
import { extractHostnameFromUrl, isValidHostname } from '@/utils/domain';
import { useRef } from 'react';
import { useRouter } from 'next/router';
import { urlBuilder } from '@/utils/urlBuilder';
import { useAuth } from 'src/hooks/useAuth';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  helperText: {
    position: 'absolute'
  }
}));

function PingForm(props) {
  const classes = useStyles();
  const { t }: { t: any } = useTranslation();
  const router = useRouter();
  const { isInitialized: authInitialized } = useAuth();
  const isMountedRef = useRefMounted();
  const { domain, ipv6, provider, continent, country, state, city, asn } =
    router.query;
  const autoStarted = useRef(false);
  const [started, setStarted] = useState(false);
  const [locations, setLocations] = useState({
    continent: [],
    country: [],
    state: [],
    city: [],
    provider: [],
    asn: [],
    worker: []
  });
  const [filters, setFilters] = useState({
    continent: [],
    country: [],
    state: [],
    city: [],
    provider: [],
    asn: [],
    worker: [],
    hostname: domain ? domain : '',
    isIPv6: ipv6 == 'true' ? true : false
  });
  const [isValid, setIsValid] = useState(false);
  const [hostnameType, setHostnameType] = useState('');
  const [openStateFilter, setOpenStateFilter] = useState(false);
  const [valuesFilled, setValuesFilled] = useState(false);

  const selectedProvider = useMemo(() => {
    if (!provider) {
      return [];
    }
    if (!Array.isArray(provider)) {
      provider = [provider];
    }

    const found = [];
    locations.provider.forEach((p) => {
      if (provider.includes(p.name)) {
        found.push(p);
      }
    });
    return found;
  }, [provider, locations.provider]);

  const selectedContinent = useMemo(() => {
    if (!continent) {
      return [];
    }
    if (!Array.isArray(continent)) {
      continent = [continent];
    }

    const found = [];
    locations.continent.forEach((c) => {
      if (continent.includes(c.name)) {
        found.push(c);
      }
    });
    return found;
  }, [continent, locations.continent]);

  const selectedCountry = useMemo(() => {
    if (!country) {
      return [];
    }
    if (!Array.isArray(country)) {
      country = [country];
    }

    const found = [];
    locations.country.forEach((c) => {
      if (country.includes(c.name)) {
        found.push(c);
      }
    });
    found.map((f) => {
      if (f.name === 'United States') {
        setOpenStateFilter(true);
      }
    });
    return found;
  }, [country, locations.country]);

  const selectedState = useMemo(() => {
    if (!state) {
      return [];
    }

    const isUS =
      selectedCountry.filter((s) => s.name === 'United States').length > 0;

    if (!isUS) return [];

    if (!Array.isArray(state)) {
      state = [state];
    }

    const found = [];

    locations.state.forEach((s) => {
      if (state.includes(s.name)) {
        found.push(s);
      }
    });

    return found;
  }, [state, locations.state, selectedCountry]);

  const selectedCity = useMemo(() => {
    if (!city) {
      return [];
    }
    if (!Array.isArray(city)) {
      city = [city];
    }

    const found = [];
    locations.city.forEach((c) => {
      if (city.includes(c.name)) {
        found.push(c);
      }
    });
    return found;
  }, [city, locations.city]);

  const selectedAsn = useMemo(() => {
    if (!asn) {
      return [];
    }
    if (!Array.isArray(asn)) {
      asn = [asn];
    }

    const found = [];
    locations.asn.forEach((c) => {
      if (asn.includes(c.name)) {
        found.push(c);
      }
    });
    return found;
  }, [asn, locations.asn]);

  const handleSubmit = () => {
    router.push(
      {
        // this is a bit of a hack,
        // the correct value of pathname should be "/[domain]"
        // and the domain key in the query object should be uncommented.
        // this is according to the documentation...
        // but the right way of doing it causes the
        // domain to be urlencoded ending with ugly urls
        // e.g.: 2a01:4f8:262:1745::2? => 2a01%3A4f8%3A262%3A1745%3A%3A2
        // causing to have urls like this /2a01%3A4f8%3A262%3A1745%3A%3A2?ivp6=true
        // so manually setting the pathname with the hostname
        // does not encode the hostname
        pathname: `/${filters.hostname.trim()}`,

        query: urlBuilder(
          filters.continent.map((i) => i.name),
          filters.country.map((i) => i.name),
          filters.state.map((i) => i.name),
          filters.city.map((i) => i.name),
          filters.provider.map((i) => i.name),
          filters.asn.map((i) => i.name),
          filters.isIPv6
        )
      },
      undefined,
      { shallow: true }
    );

    if (router.pathname !== '/[domain]') {
      return;
    }

    try {
      setStarted(true);
      props.onSubmit(filters);
    } catch (err) {
      console.error(err);
      setStarted(false);
    }
  };

  useEffect(() => {
    const isEmpty = Object.values(locations).every((x) => x.length === 0);
    if (isEmpty) {
      return;
    }

    if (provider && provider.length !== 0 && locations.provider.length == 0) {
      return;
    }

    setFilters({
      continent: selectedContinent,
      country: selectedCountry,
      state: selectedState,
      city: selectedCity,
      provider: selectedProvider,
      asn: selectedAsn,
      worker: [],
      hostname: domain ? domain : '',
      isIPv6: ipv6 == 'true' ? true : false
    });
    setValuesFilled(true);
  }, [router.query, locations]);

  useEffect(() => {
    if (filters.hostname && filters.hostname.length === 0) return;
    const validHostname = isValidHostname(filters.hostname);

    if (validHostname.type && validHostname.type !== 'domain') {
      filters.isIPv6 = validHostname.type === 'ipv6';
    }
    setHostnameType(validHostname.type);
    setIsValid(validHostname.valid);
  }, [filters.hostname]);

  const getLocations = useCallback(
    async (data: Object) => {
      try {
        const response = await apiInstance.getAllLocations(data);
        setLocations(response.data);
      } catch (err) {
        console.error(err);
      }
    },
    [isMountedRef]
  );

  useEffect(() => {
    getLocations({});
  }, [getLocations]);

  useEffect(() => {
    if (started && !props.isRunning) {
      props.handleClose();
      setStarted(false);
    }
  }, [props.isRunning]);

  // autostart hook
  useEffect(() => {
    if (
      !domain ||
      !isValid ||
      !props.canStart ||
      started ||
      !authInitialized ||
      autoStarted.current === true ||
      !valuesFilled
    ) {
      return;
    }
    setStarted(true);
    autoStarted.current = true;
    handleSubmit();
  }, [
    autoStarted.current,
    isValid,
    domain,
    props.canStart,
    started,
    valuesFilled,
    authInitialized
  ]);

  return (
    <form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={openStateFilter ? 2 : 2.4}>
              <Autocomplete
                disablePortal
                disabled={started}
                multiple
                onChange={(_, value) => {
                  setFilters((prev) => ({
                    ...prev,
                    continent: value.map((i) => i)
                  }));
                  getLocations({
                    continent: value.map((i) => i.name),
                    country: filters.country.map((i) => i.name),
                    state: filters.state.map((i) => i.name),
                    city: filters.city.map((i) => i.name),
                    provider: filters.provider.map((i) => i.id),
                    asn: filters.asn.map((i) => i.name)
                  });
                  autoStarted.current = true;

                  router.push(
                    {
                      pathname: `/${filters.hostname}`,
                      query: urlBuilder(
                        value.map((i) => i.name),
                        filters.country.map((i) => i.name),
                        filters.state.map((i) => i.name),
                        filters.city.map((i) => i.name),
                        filters.provider.map((p) => p.name),
                        filters.asn.map((i) => i.name),
                        filters.isIPv6
                      )
                    },
                    undefined,
                    { shallow: true }
                  );
                }}
                options={locations.continent}
                renderInput={(params) => (
                  <TextField fullWidth {...params} label={t('Continents')} />
                )}
                getOptionLabel={(option) =>
                  `${option.name} (${option.worker_count})`
                }
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.name}>
                      {option.name} ({option.worker_count})
                    </li>
                  );
                }}
                value={filters.continent}
              />
            </Grid>

            <Grid item xs={12} md={openStateFilter ? 2 : 2.4}>
              <Autocomplete
                disablePortal
                multiple
                disabled={started}
                onChange={(_, value) => {
                  setFilters((prev) => ({
                    ...prev,
                    country: value.map((i) => i)
                  }));
                  getLocations({
                    continent: filters.continent.map((i) => i.name),
                    country: value.map((i) => i.name),
                    state: filters.state.map((i) => i.name),
                    city: filters.city.map((i) => i.name),
                    provider: filters.provider.map((i) => i.id),
                    asn: filters.asn.map((i) => i.name)
                  });
                  autoStarted.current = true;
                  router.push(
                    {
                      pathname: `/${filters.hostname}`,
                      query: urlBuilder(
                        filters.continent.map((i) => i.name),
                        value.map((i) => i.name),
                        filters.state.map((i) => i.name),
                        filters.city.map((i) => i.name),
                        filters.provider.map((p) => p.name),
                        filters.asn.map((i) => i.name),
                        filters.isIPv6
                      )
                    },
                    undefined,
                    { shallow: true }
                  );
                  if (value.length === 0) {
                    setOpenStateFilter(false);
                  } else {
                    value.map((v) => {
                      if (v.name === 'United States') {
                        setOpenStateFilter(true);
                      } else {
                        setOpenStateFilter(false);
                      }
                    });
                  }
                }}
                options={locations.country}
                renderInput={(params) => (
                  <TextField fullWidth {...params} label={t('Countries')} />
                )}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.name}>
                      {option.name} ({option.worker_count})
                    </li>
                  );
                }}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) =>
                  `${option.name} (${option.worker_count})`
                }
                renderTags={(tagValue) => {
                  return tagValue.map((option) => (
                    <img
                      style={{
                        height: 27,
                        paddingLeft: 5,
                        paddingRight: 5,
                        alignItems: 'center',
                        justifyItems: 'center',
                        justifyContent: 'center'
                      }}
                      src={getFlagIcon(option.name)}
                    />
                  ));
                }}
                value={filters.country}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              sx={{ display: openStateFilter ? 'block' : 'none' }}
            >
              <Autocomplete
                disablePortal
                disabled={started}
                multiple
                sx={{ display: 'flex' }}
                onChange={(_, value) => {
                  setFilters((prev) => ({
                    ...prev,
                    state: value.map((i) => i)
                  }));

                  getLocations({
                    continent: filters.continent.map((i) => i.name),
                    country: filters.country.map((i) => i.name),
                    state: value.map((i) => i.name),
                    city: filters.city.map((i) => i.name),
                    provider: filters.provider.map((i) => i.id),
                    asn: filters.asn.map((i) => i.name)
                  });
                  autoStarted.current = true;
                  router.push(
                    {
                      pathname: `/${filters.hostname}`,
                      query: urlBuilder(
                        filters.continent.map((i) => i.name),
                        filters.country.map((i) => i.name),
                        value.map((i) => i.name),
                        filters.city.map((i) => i.name),
                        filters.provider.map((p) => p.name),
                        filters.asn.map((i) => i.name),
                        filters.isIPv6
                      )
                    },
                    undefined,
                    { shallow: true }
                  );
                }}
                options={locations.state}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    {...params}
                    label={t('State')}
                    sx={{ width: '100%' }}
                  />
                )}
                getOptionLabel={(option) =>
                  `${option.name} (${option.worker_count})`
                }
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.name}>
                      {option.name} ({option.worker_count})
                    </li>
                  );
                }}
                value={filters.state}
              />
            </Grid>
            <Grid item xs={12} md={openStateFilter ? 2 : 2.4}>
              <Autocomplete
                multiple
                disabled={started}
                disablePortal
                options={locations.city}
                onChange={(_, value) => {
                  setFilters((prev) => ({
                    ...prev,
                    city: value.map((i) => i)
                  }));
                  getLocations({
                    continent: filters.continent.map((i) => i.name),
                    country: filters.country.map((i) => i.name),
                    state: filters.state.map((i) => i.name),
                    city: value.map((i) => i.name),
                    provider: filters.provider.map((i) => i.id),
                    asn: filters.asn.map((i) => i.name)
                  });
                  autoStarted.current = true;
                  router.push(
                    {
                      pathname: `/${filters.hostname}`,
                      query: urlBuilder(
                        filters.continent.map((i) => i.name),
                        filters.country.map((i) => i.name),
                        filters.state.map((i) => i.name),
                        value.map((i) => i.name),
                        filters.provider.map((p) => p.name),
                        filters.asn.map((i) => i.name),
                        filters.isIPv6
                      )
                    },
                    undefined,
                    { shallow: true }
                  );
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.name}>
                      {option.name} ({option.worker_count})
                    </li>
                  );
                }}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) =>
                  `${option.name} (${option.worker_count})`
                }
                renderInput={(params) => (
                  <TextField fullWidth {...params} label={t('Cities')} />
                )}
                value={filters.city}
              />
            </Grid>
            <Grid item xs={12} md={openStateFilter ? 2 : 2.4}>
              <Autocomplete
                multiple
                disablePortal
                disabled={started}
                options={locations.provider}
                onChange={(_, value) => {
                  setFilters((prev) => ({
                    ...prev,
                    provider: value.map((i) => i)
                  }));
                  getLocations({
                    continent: filters.continent.map((i) => i.name),
                    country: filters.country.map((i) => i.name),
                    state: filters.state.map((i) => i.name),
                    city: filters.city.map((i) => i.name),
                    provider: value.map((item) => item.id),
                    asn: filters.asn.map((i) => i.name)
                  });
                  autoStarted.current = true;
                  router.push(
                    {
                      pathname: `/${filters.hostname}`,
                      query: urlBuilder(
                        filters.continent.map((i) => i.name),
                        filters.country.map((i) => i.name),
                        filters.state.map((i) => i.name),
                        filters.city.map((i) => i.name),
                        value.map((p) => p.name),
                        filters.asn.map((i) => i.name),
                        filters.isIPv6
                      )
                    },
                    undefined,
                    { shallow: true }
                  );
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) =>
                  `${option.name} (${option.worker_count})`
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name} ({option.worker_count})
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField fullWidth {...params} label={t('Providers')} />
                )}
                value={filters.provider}
              />
            </Grid>
            <Grid item xs={12} md={openStateFilter ? 2 : 2.4}>
              <Autocomplete
                multiple
                disabled={started}
                disablePortal
                options={locations.asn}
                onChange={(_, value) => {
                  setFilters((prev) => ({
                    ...prev,
                    asn: value.map((i) => i)
                  }));
                  autoStarted.current = true;
                  getLocations({
                    continent: filters.continent.map((i) => i.name),
                    country: filters.country.map((i) => i.name),
                    state: filters.state.map((i) => i.name),
                    city: filters.city.map((i) => i.name),
                    provider: filters.provider.map((i) => i.id),
                    asn: value.map((i) => i.name)
                  });
                  router.push(
                    {
                      pathname: `/${filters.hostname}`,
                      query: urlBuilder(
                        filters.continent.map((i) => i.name),
                        filters.country.map((i) => i.name),
                        filters.state.map((i) => i.name),
                        filters.city.map((i) => i.name),
                        filters.provider.map((p) => p.name),
                        value.map((i) => i.name),
                        filters.isIPv6
                      )
                    },
                    undefined,
                    { shallow: true }
                  );
                }}
                value={filters.asn}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                renderInput={(params) => (
                  <TextField fullWidth {...params} label={t('ASN')} />
                )}
                getOptionLabel={(option) =>
                  `${option.name} (${option.worker_count})`
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.name}>
                      {option.name} ({option.worker_count})
                    </li>
                  );
                }}
              />
            </Grid>

            <Grid item xs={12} md={4.8} sx={{ position: 'relative' }}>
              <TextField
                error={!isValid}
                fullWidth
                disabled={started}
                sx={{
                  '& .MuiFormHelperText-root': {
                    bottom: '-1.5rem'
                  }
                }}
                autoComplete="off"
                helperText={isValid ? '' : 'Input a valid hostname'}
                FormHelperTextProps={{
                  className: classes.helperText
                }}
                onKeyUp={(event) => {
                  if (event.keyCode === 13) {
                    document.getElementById('submit').click();
                  }
                }}
                label={t('IPv4 / IPv6 / Hostname')}
                name="hostname"
                onChange={(e: any) => {
                  const hostname = extractHostnameFromUrl(e.target.value);
                  setFilters((prev) => ({
                    ...prev,
                    hostname: hostname
                  }));
                }}
                // onBlur={formik.handleBlur}
                value={filters.hostname}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={2.4}
              sx={{
                mt: {
                  xs: 2,
                  md: 0
                }
              }}
            >
              <Box sx={{ display: 'flex', width: '100%', height: '100%' }}>
                <Button
                  onClick={() => {
                    setFilters((prev) => ({
                      ...prev,
                      isIPv6: false
                    }));
                  }}
                  disabled={hostnameType === 'ipv6' || started}
                  sx={{
                    width: '50%',
                    height: '100%',
                    borderRight: 0,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0
                  }}
                  variant={
                    hostnameType === 'ipv6'
                      ? 'outlined'
                      : filters.isIPv6 === false
                      ? 'contained'
                      : 'outlined'
                  }
                >
                  IPv4
                </Button>
                <Button
                  onClick={() =>
                    setFilters((prev) => ({
                      ...prev,
                      isIPv6: true
                    }))
                  }
                  disabled={hostnameType === 'ipv4' || started}
                  sx={{
                    width: '50%',
                    height: '100%',
                    borderLeft: 0,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0
                  }}
                  variant={
                    hostnameType === 'ipv4'
                      ? 'outlined'
                      : filters.isIPv6 === true
                      ? 'contained'
                      : 'outlined'
                  }
                >
                  IPv6
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={2.4}>
              <Button
                sx={{ width: '100%', height: '100%' }}
                disabled={started}
                onClick={() => {
                  router.push('/');
                  setFilters({
                    continent: [],
                    country: [],
                    state: [],
                    city: [],
                    provider: [],
                    asn: [],
                    worker: [],
                    isIPv6: false
                  });
                }}
                variant="outlined"
                startIcon={<RotateLeftIcon />}
              >
                {t('Reset')}
              </Button>
            </Grid>
            <Grid item xs={12} md={2.4}>
              {started && (
                <Button
                  sx={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'darkred'
                  }}
                  onClick={() => {
                    props.handleClose();
                  }}
                  type={'button'}
                  disabled={!((isValid && !domain) || (isValid && domain))}
                  variant={'contained'}
                >
                  {t('Stop Ping')}
                </Button>
              )}

              {!started && (
                <Button
                  id={'submit'}
                  sx={{
                    '&:hover': { backgroundColor: 'green' },
                    width: '100%',
                    height: '100%',
                    backgroundColor: isValid ? '#39B58A' : ''
                  }}
                  type="button"
                  onClick={() => {
                    handleSubmit();
                  }}
                  // startIcon={
                  //   formik.isSubmitting ? (
                  //     <CircularProgress size="1rem" />
                  //   ) : null
                  // }
                  disabled={
                    !(
                      (isValid && !domain && props.canStart) ||
                      (isValid && domain && props.canStart)
                    )
                  }
                  variant={'contained'}
                >
                  {t('Run Ping')}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default memo(PingForm);
