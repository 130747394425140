import { useState, useEffect, useRef, useCallback } from 'react';
import { Worker as WorkerType } from '@/models/worker';
import firebase from 'src/utils/firebase';
import { useAuth } from 'src/hooks/useAuth';

interface IUseFeedWorker {
  status: string;
  runStatus: string;
  feed: Worker | null;
  workers: Array<any>;
  pingData: object;
  resetState: Function;
  reportId: string;
  reportDate: string;
  reportDomain: string;
}

export const useWorker = (errorCallback): IUseFeedWorker => {
  const [status, setStatus] = useState('loading');
  const [runStatus, setRunStatus] = useState('stopped');
  const [workers, setWorkers] = useState<WorkerType[]>([]);
  const [data, setData] = useState({});
  const [reverseDns, setReverseDns] = useState({});

  const [reportId, setReportId] = useState('');
  const [reportDate, setReportDate] = useState('');
  const [reportDomain, setReportDomain] = useState('');

  const { isInitialized: authInitialized } = useAuth();

  const worker = useRef<Worker>();

  const resetState = useCallback(() => {
    setWorkers([]);
    setData({});
    setReverseDns({});
  }, [setWorkers, setData, setReverseDns]);

  const run = useCallback(
    (data: object) => {
      worker.current?.postMessage({
        type: 'RUN',
        data
      });
      setRunStatus('running');
    },
    [worker.current, setRunStatus]
  );

  const connect = useCallback(async () => {
    let token;
    const currentUser = firebase.auth().currentUser;
    if (currentUser) {
      token = await currentUser.getIdToken();
    }
    worker.current?.postMessage({ type: 'CONNECT', token: token });
  }, [worker.current, firebase]);

  const stop = useCallback(() => {
    worker.current?.postMessage({
      type: 'CLOSE'
    });
    setRunStatus('stopped');
    connect();
  }, [worker.current, connect, setRunStatus]);

  useEffect(() => {
    worker.current = new Worker(new URL('@/ping.worker', import.meta.url));
    worker.current.onmessage = (event) => {
      const data = event.data.data;
      switch (event.data.type) {
        case 'SOCKET_CONNECTED': {
          setStatus('ready');
          break;
        }
        case 'RUN_WORKERS': {
          setWorkers(data);
          break;
        }
        case 'RUN_ERROR': {
          errorCallback('runError', data);
          break;
        }
        case 'RUN_WORKER_ERROR': {
          errorCallback('workerError', data);
          break;
        }
        case 'RUN_RECAPTCHA_ERROR': {
          errorCallback('recaptchaError', data);
          break;
        }
        case 'RUN_BATCH_REVERSE_DNS': {
          setReverseDns((prevState) => {
            const newData = {};

            for (const value of data) {
              newData[value.destination] = value.response;
            }
            return { ...prevState, ...newData };
          });
          break;
        }
        case 'RUN_FINISHED': {
          setRunStatus('stopped');
          setReportDomain(data.report_domain);
          setReportDate(data.report_date);
          setReportId(data.report_id);
          break;
        }
        case 'RUN_BATCH_RESPONSE': {
          setData((prevState) => {
            const newData = {};

            for (const value of data) {
              newData[value.worker_id] = value;
            }
            return { ...prevState, ...newData };
          });
        }
      }
    };

    // cleanup
    return () => {
      worker.current.postMessage({ type: 'CLOSE' });
      worker.current.terminate();
    };
  }, []);

  useEffect(() => {
    // worker is not initialized yet, exit
    if (!worker.current) return;

    // wait for authentication to finish
    if (!authInitialized) return;
    connect();
  }, [authInitialized, status, connect, worker.current]);

  if (worker && worker?.current && status === 'ready') {
    return {
      status,
      runStatus,
      run,
      stop,
      workers,
      resetState,
      data,
      reverseDns,
      reportId,
      reportDate,
      reportDomain
    };
  }

  return {
    status,
    runStatus,
    run,
    stop,
    workers,
    resetState,
    data,
    reverseDns,
    reportId,
    reportDate,
    reportDomain
  };
};
