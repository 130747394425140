import {
  Typography,
  Box,
  Avatar,
  Card,
  Grid,
  useTheme,
  styled
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import FlagIcon from '@mui/icons-material/Flag';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import StorageIcon from '@mui/icons-material/Storage';
import CloudIcon from '@mui/icons-material/Cloud';
import { useCallback, useEffect, useState } from 'react';
import { apiInstance } from '@/config/api';
import { useRefMounted } from '@/hooks/useRefMounted';

const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
      color:  ${theme.colors.alpha.trueWhite[100]};
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
`
);

function Block3() {
  const { t }: { t: any } = useTranslation();
  const theme = useTheme();
  const [locations, setLocations] = useState({
    continent: [],
    country: [],
    city: [],
    provider: [{}],
    asn: [],
    workerCount: 0
  });
  const isMountedRef = useRefMounted();

  const getLocations = useCallback(
    async (data: Object) => {
      try {
        const response = await apiInstance.getAllLocations(data);
        setLocations({
          continent: response.data.continent,
          country: response.data.country,
          city: response.data.city,
          provider: response.data.provider,
          asn: response.data.asn,
          workerCount: response.data.worker_count
        });
      } catch (err) {
        console.error(err);
      }
    },
    [isMountedRef]
  );

  useEffect(() => {
    getLocations({});
  }, [getLocations]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} lg={2.4}>
        <Card
          sx={{
            px: 1,
            py: 1,
            display: 'flex'
          }}
        >
          <Box
            display="flex"
            flexDirection={'row'}
            alignItems="center"
            flex={1}
          >
            <AvatarWrapper
              sx={{
                background: `${theme.colors.gradients.black2}`
              }}
            >
              <StorageIcon fontSize="small" />
            </AvatarWrapper>
            <Typography
              sx={{
                ml: 1.5,
                fontSize: `${theme.typography.pxToRem(15)}`,
                fontWeight: 'bold'
              }}
              variant="subtitle2"
              component="div"
            >
              {t('Workers')}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              justifyContent: 'center'
            }}
          >
            <Typography
              sx={{
                pl: 1,
                fontSize: `${theme.typography.pxToRem(25)}`
              }}
              variant="h4"
            >
              {locations.workerCount}
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4} lg={2.4}>
        <Card
          sx={{
            px: 1,
            py: 1,
            display: 'flex'
          }}
        >
          <Box display="flex" alignItems="center" flex={1}>
            <AvatarWrapper
              sx={{
                background: `${theme.colors.gradients.orange2}`
              }}
            >
              <CloudIcon fontSize="small" />
            </AvatarWrapper>
            <Typography
              sx={{
                ml: 1.5,
                fontSize: `${theme.typography.pxToRem(15)}`,
                fontWeight: 'bold'
              }}
              variant="subtitle2"
              component="div"
            >
              {t('Providers')}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              justifyContent: 'center'
            }}
          >
            <Typography
              sx={{
                pl: 1,
                fontSize: `${theme.typography.pxToRem(25)}`
              }}
              variant="h1"
            >
              {locations.provider.length}
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4} lg={2.4}>
        <Card
          sx={{
            px: 1,
            py: 1,
            display: 'flex'
          }}
        >
          <Box display="flex" alignItems="center" flex={1}>
            <AvatarWrapper
              sx={{
                background: `${theme.colors.gradients.purple3}`
              }}
            >
              <SouthAmericaIcon fontSize="small" />
            </AvatarWrapper>
            <Typography
              sx={{
                ml: 1.5,
                fontSize: `${theme.typography.pxToRem(15)}`,
                fontWeight: 'bold'
              }}
              variant="subtitle2"
              component="div"
            >
              {t('Continents')}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              justifyContent: 'center'
            }}
          >
            <Typography
              sx={{
                pl: 1,
                fontSize: `${theme.typography.pxToRem(25)}`
              }}
              variant="h1"
            >
              {locations.continent.length}
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} lg={2.4}>
        <Card
          sx={{
            px: 1,
            py: 1,
            display: 'flex'
          }}
        >
          <Box display="flex" alignItems="center" flex={1}>
            <AvatarWrapper
              sx={{
                background: `${theme.colors.gradients.blue4}`
              }}
            >
              <FlagIcon fontSize="small" />
            </AvatarWrapper>
            <Typography
              sx={{
                ml: 1.5,
                fontSize: `${theme.typography.pxToRem(15)}`,
                fontWeight: 'bold'
              }}
              variant="subtitle2"
              component="div"
            >
              {t('Countries')}
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              justifyContent: 'center'
            }}
          >
            <Typography
              sx={{
                pl: 1,
                fontSize: `${theme.typography.pxToRem(25)}`
              }}
              variant="h1"
            >
              {locations.country.length}
            </Typography>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} lg={2.4}>
        <Card
          sx={{
            px: 1,
            py: 1,
            display: 'flex'
          }}
        >
          <Box display="flex" alignItems="center" sx={{ flex: 1 }}>
            <AvatarWrapper
              sx={{
                background: `${theme.colors.gradients.green2}`
              }}
            >
              <LocationCityIcon fontSize="small" />
            </AvatarWrapper>
            <Typography
              sx={{
                ml: 1.5,
                fontSize: `${theme.typography.pxToRem(15)}`,
                fontWeight: 'bold'
              }}
              variant="subtitle2"
              component="div"
            >
              {t('Cities')}
            </Typography>
          </Box>
          <Box
            alignItems={'center'}
            display="flex"
            sx={{
              justifyContent: 'center'
            }}
          >
            <Typography
              sx={{
                pl: 1,
                fontSize: `${theme.typography.pxToRem(25)}`
              }}
              variant="h1"
            >
              {locations.city.length}
            </Typography>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

export default Block3;
