import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  CircularProgress,
  Tooltip,
  Button,
  Divider,
  Box,
  useTheme,
  Link
} from '@mui/material';
import { useEffect, useCallback, useState } from 'react';
import { apiInstance } from '@/config/api';
import { useRefMounted } from '@/hooks/useRefMounted';
import { formatDistanceToNow } from 'date-fns';
import { useRouter } from 'next/router';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { memo } from 'react';
import { format } from 'date-fns';
import { useAuth } from '@/hooks/useAuth';

const PingHistoryTable = ({ pingReportId }) => {
  const theme = useTheme();
  const isMountedRef = useRefMounted();
  const router = useRouter();
  const [pingHistory, setPingHistory] = useState([]);
  const [infoMessage, setInfoMessage] = useState('');
  const { user } = useAuth();

  const getHistory = useCallback(async () => {
    try {
      const response = await apiInstance.getPingHistory();
      setPingHistory(response.data);
      if (!response.data.length) {
        setInfoMessage('There is no data to display.');
      }
    } catch (err) {
      console.error(err);
      setInfoMessage('An error has ocurred, please try again later.');
    }
  }, [isMountedRef]);

  const getMyHistory = useCallback(async () => {
    try {
      const response = await apiInstance.getMyHistory({ limit: 15 });
      setPingHistory(response.data);
      if (!response.data.length) {
        setInfoMessage('There is no data to display.');
      }
    } catch (err) {
      console.error(err);
      setInfoMessage('An error has ocurred, please try again later.');
    }
  }, [isMountedRef]);

  useEffect(() => {
    if (user) {
      getMyHistory();
    } else {
      getHistory();
    }
  }, [getHistory, getMyHistory, user]);

  useEffect(() => {
    if (pingReportId && user) {
      getMyHistory();
    }
    if (pingReportId && !user) {
      getHistory();
    }
  }, [pingReportId]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box display={'flex'} justifyContent="space-between">
          <Typography variant="h3">Ping history</Typography>
          {user && (
            <Link
              sx={{
                px: 1
              }}
              href={`/history`}
              variant={'body1'}
              underline={'always'}
            >
              My history
            </Link>
          )}
        </Box>
        <Divider sx={{ paddingBottom: 0.5 }} />
      </Grid>

      <Grid item xs={12} pt={1}>
        <TableContainer>
          {pingHistory.length !== 0 ? (
            <Table sx={{ tableLayout: 'fixed', width: '100%' }} size="small">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: 'auto', padding: 1 }}>
                    Domain
                  </TableCell>
                  <TableCell
                    sx={{ width: { xs: '140px', md: '200px' }, padding: 0 }}
                  >
                    Time
                  </TableCell>
                  <TableCell
                    sx={{ width: { xs: '20px', md: '100px' } }}
                    align="center"
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pingHistory.map((ping) => (
                  <TableRow key={ping.uuid}>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        whiteSpace: 'nowrap',
                        paddingY: 0,
                        paddingLeft: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {ping.domain}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: 'bold',
                        padding: 0,
                        whiteSpace: 'nowrap'
                      }}
                    >
                      {`${formatDistanceToNow(new Date(ping.created_at))} ago`}
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ padding: 0, margin: 0, whiteSpace: 'nowrap' }}
                    >
                      <Link
                        sx={{
                          display: {
                            xs: 'none',
                            md: 'block'
                          }
                        }}
                        href={`/reports/${ping.uuid}/${format(
                          new Date(ping.created_at),
                          'dd-MM-yyyy'
                        )}/${ping.domain}`}
                        variant={'body1'}
                        target={'_blank'}
                        underline={'always'}
                      >
                        View Results
                      </Link>
                      <Link
                        href={`/reports/${ping.uuid}/${format(
                          new Date(ping.created_at),
                          'dd-MM-yyyy'
                        )}/${ping.domain}`}
                        variant={'body1'}
                        target={'_blank'}
                      >
                        <ChevronRightIcon
                          sx={{
                            display: {
                              xs: 'flex',
                              md: 'none'
                            },
                            flexDirection: 'column',
                            justifyContent: 'center',
                            color: theme.colors.primary.main
                          }}
                        />
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <Typography
              sx={{
                py: 2
              }}
              variant="h4"
              fontWeight="normal"
              color="text.secondary"
              align="center"
            >
              {infoMessage}
            </Typography>
          )}
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default memo(PingHistoryTable);
