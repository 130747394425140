import Head from 'next/head';
import PingContent from '@/content/Ping';

function Ping() {
  return (
    <>
      <Head>
        <title>Ping Latency Tool</title>
        <meta charSet="UTF-8" />
        <meta
          name="description"
          content="Test the latency of your server from all over the world with this awesome &amp; free ping benchmark tool"
        />
        <meta
          name="keywords"
          content="ping, latency, tool, ping latency, benchmark server latency, ping server"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <PingContent />
    </>
  );
}

export default Ping;
